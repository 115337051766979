z.document {
  display: flex;
  flex-direction: column;
  height: 75vh;
  overflow: hidden;
  overflow-y: scroll;
  align-items: center;
}

.react-pdf__Page__canvas {
  top: 0;
  border-radius: 10px;
  border: 1px solid black;
}

.react-tel-input .form-control:focus {
  border: '0.5px solid rgba(161, 176, 196, 1)';
}

body.body-patient {
  background-color: #dde3ea;
}

/* body::-webkit-scrollbar {
  background-color: #d7e0eb;
  width: 0.5rem;
  padding: 10px;
  margin: 10px;
}
body::-webkit-scrollbar-thumb {
  background-color: #b5b5b5;
  border-radius: 20px;
} */

.MuiTouchRipple-root {
  display: none;
}