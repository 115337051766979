.wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45vw;
  padding: 24px;
  gap: 4px;
  border-radius: 12px;
  border: 1px solid #EAECF0;
  background-color: white;

}
.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45vw;
  height: 20vh;
}
.upload-text {
font-family: Inter;
font-size: 14px;
font-weight: 600;
line-height: 20px;
text-align: left;
color: #475467;

}
.image-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 10px 0px;
  margin-top: 1rem;
  gap: 0px;
  border-radius: 8px;
  border: 1px solid #EAECF0;
  opacity: 0px;
}
.click-text {
  color: #0054B6
}
.image-tool__file-size-info {
color: #475467;
font-family: Inter;
font-size: 12px;
font-weight: 400;
line-height: 18px;
text-align: center;
}
.image {
  width: 60%;
  height: 60%;
}
.link-tool__input{

color: #147AF3 !important;
text-decoration: underline;
box-sizing: border-box;

display: flex;
flex-direction: column;
align-items: flex-start;
padding: 10px 14px;
gap: 10px;

width: 45vw;
height: 40px;
background: #FFFFFF;
border: 1px solid #D0D5DD;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;

/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;

}
.hidden-input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

