.c-timeline__itemDoctor {
  position: relative;
  display: flex;
  gap: 1.5rem;
  width: 100%;
}
.c-timeline {
  width: 100%;
}

.c-timeline__itemDoctor:last-child .c-timeline__contentDoctor:before {
  display: none;
}

.c-timeline__contentDoctor {
  flex: 1;
  width: 100%;
  position: relative;
  order: 1;
  padding-left: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.c-timeline__contentDoctor.skeleton:after {
  background-color: rgba(0, 0, 0, 0.11);
  border: 3px solid rgba(0, 0, 0, 0.11);
  animation: MuiSkeleton-keyframes-pulse 1.5s ease-in-out 0.5s infinite;
}

.c-timeline__contentDoctor:before {
  content: '';
  position: absolute;
  right: 100%;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: #53b1fd;
}

.c-timeline__contentDoctor:after {
  content: '';
  position: absolute;
  left: calc(0px - 12.5px);
  top: 0;
  width: 13px;
  height: 13px;
  background-color: #53b1fd;
  z-index: 1;
  border: 6px solid #e0f2fe;
  border-radius: 100%;
}

.c-timeline__titleDoctor {
  margin: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #425364;
}

.c-timeline__descDoctor .public-DraftStyleDefault-ltr {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  padding: 0;
  color: #425364;
}
.c-timeline__descDoctor {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  margin: 0;
  padding: 0;
  color: #425364;
  margin-top: 5px;
  margin-bottom: 40px;
}
.c-timeline__dateDoctor {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  color: #475467;
}

ol {
  padding: 0;
}
