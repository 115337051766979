.invoice-preview {
    height: 0;
    padding-inline: 2rem;
}

.preview-section {
    margin-bottom: 30px;
}

.preview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.preview-title {
    font-size: 32px;
    margin: 0;
    font-weight: 600;
    color: #3f4857;
}

.preview-details {
    margin-top: 10px;
}

.detail-row {
    display: flex;
    margin-bottom: 5px;
}

.detail-label {
    font-weight: 400;
    margin-right: 5px;
    font-size: 16px;
    color: #425364;
}

.detail-text {
    font-size: 16px;
    font-weight: 600;
    color: #425364;
}

.from-details,
.bill-to-details {
    margin-top: 5px;
}

.name {
    font-weight: 600;
    font-size: 16px;
    color: #425364;
}

.email,
.phone,
.address {
    font-weight: 400;
    font-size: 14px;
    color: #425364;
}

.name,
.email,
.phone,
.address {
    display: block;
    margin-bottom: 5px;
}

.section-title {
    font-size: 14px;
    font-weight: 600;
    color: #425364;
}

.invoice-table {
    width: 100%;
    border-collapse: collapse;
}

th {
    font-weight: 500;
    font-size: 16px;
    color: #475467;
}

td {
    font-weight: 500;
    font-size: 16px;
    color: #475467;
}

.balance-total {
    font-weight: 500;
    font-size: 16px;
    color: #475467;
}

.invoice-table th,
.invoice-table td {
    padding: 8px;
    text-align: left;
}

.balance-section {
    margin-top: 20px;
}

.balance-row {
    display: flex;
    justify-content: space-between;
    width: 250px;
    align-items: center;
    margin-bottom: 10px;
    padding-inline: 5px;
    padding-block: 5px;
}

.balance-label {
    font-weight: 600;
    font-size: 16px;
    color: #475467;
}

.balance-labelpaid {
    font-weight: 400;
    font-size: 16px;
    color: #475467;
}

.balance-due {
    font-weight: 700;
    font-size: 20px;
    color: #475467;
}

.balance-row:last-child {
    background-color: #eff6ff;
}

.balance-amount {
    font-weight: bold;
}

thead {
    background-color: #f2f4f7;
}

.vertical-divider {
    width: 1px;
    background-color: #D0D5DD;
    height: auto;
}